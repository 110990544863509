import {createTheme} from "@mui/material";

export const darkTheme = createTheme({
    spacing: 4,
    palette: {
        text:{
            primary: "#FFFFFF"
        }
    },
    typography: {
        fontFamily: [
          'PingFang SC',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            color: "#FFFFFF",
            fontWeight: 700,
        },
        h2: {
            color: "#FFFFFF",
            fontWeight: 700,
        },
        h3: {
            color: "#FFFFFF",
            fontWeight: 700,
        },
        h4: {
            color: "#FFFFFF",
            fontWeight: 600,
        },
        h5: {
            color: "#FFFFFF",
            fontWeight: "600",
        },
        h6: {
            color: "#FFFFFF"
        },
        body1: {
            color: "rgba(255, 255, 255, 0.6)",
            textAlign: "left"
        },
        body2: {
            color: "rgba(255, 255, 255, 0.6)",
            textAlign: "left"
        }
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h2',
                    h2: 'h2',
                    h3: 'h2',
                    h4: 'h2',
                    h5: 'h2',
                    h6: 'h2',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'span',
                    body2: 'span',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    background: "linear-gradient(94.07deg, #F143E2 5.19%, #40187F 100%)",
                    height: 4,
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    minHeight: "50px",
                    color: "rgb(255, 255, 255)",
                    opacity: 0.6,
                    ".Mui-selected": {
                        color: "rgb(255, 255, 255) !important",
                        fontWeight: "bold"
                    }
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    background: "linear-gradient(93.66deg, #FB36F4 0%, #9D00C6 100%);",
                    fontFamily: "PingFang SC",
                    fontWeight: 600
                }
            }
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    backgroundSize: "initial",
                    width: "inherit"
                }
            }
        },
        MuiImageListItem: {
            styleOverrides: {
                img: {
                    objectFit: "inherit",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "rgba(255, 255, 255, 0.6)",
                }
            }
        }

    }
})
