import React, { useState } from "react";
import { Box, Button, Drawer, Tab, Tabs, IconButton } from "@mui/material";
import { ReactComponent as MoveFlowLogo } from "resources/MFlogo.svg";
import { launchApp } from "utils";
import MenuIcon from "@mui/icons-material/Menu";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import { ReactComponent as GitbookLogo } from "resources/gitbook.svg";

const Header = () => {
  const [tabValue, setTabValue] = useState<string>("Home");
  const [openDrawer, setOpenDrawer] = useState(false);

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    let anchorElement = document.getElementById(newValue);

    if (anchorElement) {
      anchorElement.scrollIntoView({ block: "start", inline: "nearest" });
    }

    setTabValue(newValue);
    handleCloseDrawer();
  };

  const handleIconClick = (url: string) => {
    window.open(url);
    handleCloseDrawer();
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <div className="">
      <div className="flex flex-row items-center justify-between h-20">
        <Box>
          <MoveFlowLogo
            height="30px"
            width="150px"
            style={{ left: "-300px" }}
          />
        </Box>

        <div className="lg:hidden flex flex-row items-center gap-x-5">
          {/* Show MenuIcon only on tablet and mobile screens */}
          {/* <Button variant="contained" onClick={launchApp}>
            Launch App
          </Button> */}
          <IconButton onClick={handleDrawerOpen} color="inherit">
            <MenuIcon />
          </IconButton>
        </div>

        <div className="hidden lg:flex flex-row items-center justify-end gap-x-5">
          <Tabs value={tabValue} onChange={onTabChange} textColor="inherit">
            <Tab label="Home" value="Home" />
            <Tab label="Product" value="Product" />
            <Tab label="Partner" value="Partner" />
          </Tabs>
          <Button variant="contained" onClick={launchApp}>
            Launch App
          </Button>
        </div>
      </div>

      <Drawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        anchor="right"
        PaperProps={{
          sx: {
            background: "black",
            width: "300px",
          },
        }}
      >
        {" "}
        <Box sx={{ margin: "30px" }}>
          <MoveFlowLogo width="150px" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={onTabChange}
            orientation="vertical"
            textColor="inherit"
            TabIndicatorProps={{
              style: { transform: "none", display: "none" },
            }}
          >
            <Tab label="Home" value="Home" />
            <Tab label="Product" value="Product" />
            <Tab label="Partners" value="Partner" />
          </Tabs>

          <div className="flex flex-row items-center mt-6">
            <IconButton
              aria-label="github"
              onClick={() => {
                handleIconClick("https://github.com/Move-Flow");
              }}
              sx={{ paddingLeft: 0 }}
            >
              <GitHubIcon sx={{ color: "grey" }} fontSize="large" />
            </IconButton>
            <IconButton
              onClick={() => {
                handleIconClick("https://twitter.com/moveflowlabs");
              }}
            >
              <TwitterIcon sx={{ color: "grey" }} fontSize="large" />
            </IconButton>
            <IconButton
              onClick={() => {
                handleIconClick("https://moveflow.gitbook.io/moveflow/");
              }}
            >
              <GitbookLogo />
            </IconButton>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default Header;
