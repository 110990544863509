// import { Box, Button, Typography } from "@mui/material";
// import Footer from "components/Footer";
// import Header from "components/Header";
// // import MyCard, { MyCardProps } from "components/MyCard";
// // import React from "react";
// // import Albin from "resources/avatar/albin.png";
// // import Bob from "resources/avatar/bob.png";
// // import Burt from "resources/avatar/burt.png";
// // import Murph from "resources/avatar/murph.png";
// // import Terry from "resources/avatar/terry.png";
// // import Troy from "resources/avatar/troy.png";
// // import Vicky from "resources/avatar/vicky.png";
// // import Walter from "resources/avatar/walter.png";
// import "./App.css";

// import { ReactComponent as HeadFigure } from "resources/head-figure.svg";
// import { ReactComponent as Antalpha } from "resources/partner/antalpha_labs.svg";
// import { ReactComponent as Aptos } from "resources/partner/aptos_logo.svg";
// import MoveBit from "resources/partner/movebits.png";
// import MoveFuns from "resources/partner/movefuns.png";
// import { ReactComponent as MSafe } from "resources/partner/msafe.svg";
// import NonceGeek from "resources/partner/noncegeek.png";
// import { ReactComponent as OV } from "resources/partner/ov.svg";
// import { ReactComponent as Zonff } from "resources/partner/zonff_logo.svg";
// import { ReactComponent as EventBase } from "resources/products/EventBase.svg";
// import QuestReward from "resources/products/Quest_Reward.svg";
// // import { ReactComponent as QuestReward } from "resources/products/Quest.svg";
// import { ReactComponent as SDK } from "resources/products/SDK.svg";
// import { ReactComponent as TimeBase } from "resources/products/TimeBase.svg";
// import { ReactComponent as AddressBook } from "resources/products/address_book.svg";
// import { ReactComponent as Dashboard } from "resources/products/dashboard.svg";
// import { ReactComponent as Subscriptions } from "resources/products/subscriptions.svg";

// function App() {
//   const launchApp = () => {
//     window.open("https://app.moveflow.xyz/");
//   };

//   const developerDoc = () => {
//     window.open("https://moveflow.gitbook.io/moveflow/");
//   };

//   return (
//     <div className="App">
//       <Header />
//       <Box
//         sx={{
//           px: 30,
//         }}
//       >
//         <Box sx={{ display: "flex" }}>
//           <div className="flex flex-row gap-x-1 justify-between">
//             <div className="basis-4/5 pt-40">
//               <Typography
//                 variant="h2"
//                 component="div"
//                 sx={{
//                   fontWeight: "bold",
//                   textAlign: "left",
//                   marginBottom: 14,
//                   width: "100%",
//                 }}
//               >
//                 Multi-Chain Programmable Payment Protocol
//               </Typography>
//               <Typography
//                 variant="body1"
//                 mt={5}
//                 component="div"
//                 sx={{
//                   fontWeight: "bold",
//                   textAlign: "left",
//                   // color: "rgba(255, 255, 255, 0.6)"
//                 }}
//               >
//                 MoveFlow is a programmable payment protocol that enables users
//                 to set up pre-defined transaction rules based on time and
//                 events. We help you streamline complex contract-based payment
//                 process through automated smart contracts.
//               </Typography>
//               <Box sx={{ display: "flex", alignItems: "start", gap: 5 }}>
//                 <Button
//                   variant="contained"
//                   onClick={launchApp}
//                   sx={{
//                     marginTop: 10,
//                     position: "relative",
//                     left: 0,
//                     width: 320,
//                     height: 78,
//                     fontSize: 20,
//                     fontWeight: 600,
//                     borderRadius: "8px",
//                   }}
//                 >
//                   Launch App
//                 </Button>
//                 <Button
//                   variant="contained"
//                   onClick={developerDoc}
//                   sx={{
//                     marginTop: 10,
//                     position: "relative",
//                     left: 0,
//                     width: 320,
//                     height: 78,
//                     fontSize: 20,
//                     fontWeight: 600,
//                     color: "#FB36F4",
//                     background: "transparent",
//                     border: "2px solid #FB36F4",
//                     borderRadius: "8px",
//                     "&:hover": {
//                       background: "transparent",
//                       boxShadow: "none",
//                     },
//                   }}
//                 >
//                   Developer Doc
//                 </Button>
//               </Box>
//             </div>
//             <div className="basis-1/5">
//               <HeadFigure />
//             </div>
//           </div>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             marginTop: 20,
//             gap: 5,
//           }}
//         >
//           <Typography variant="h3">Programmable Payment Solution</Typography>
//           <div className="flex flex-row mt-5 gap-x-5 pt-2">
//             <div className="basis-1/2 md:basis-full">
//               <div className="basis-1/2 md:basis-full h-80">
//                 <TimeBase width="100%" height="100%" className="l-0" />
//               </div>
//               <Typography
//                 variant="h5"
//                 component="div"
//                 sx={{ textAlign: "left", marginBottom: 12, marginTop: 5 }}
//               >
//                 Time-based Programmable Payment Product
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="div"
//                 sx={{ textAlign: "left", marginTop: 5, lineHeight: "30px" }}
//               >
//                 MoveFlow's time-based programmable payment product allows users
//                 to set up payment rules based on time parameters, enabling
//                 automated payments like recurring payroll, token management and
//                 subscription.
//               </Typography>
//             </div>
//             <div className="basis-1/2 md:basis-full">
//               <div className="basis-1/2 md:basis-full h-80">
//                 <EventBase width="100%" height="100%" />
//               </div>
//               <Typography
//                 variant="h5"
//                 component="div"
//                 sx={{ textAlign: "left", marginBottom: 12, marginTop: 5 }}
//               >
//                 Event-based Programmable Payment Product
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="div"
//                 sx={{ textAlign: "left", marginTop: 5, lineHeight: "30px" }}
//               >
//                 MoveFlow's event-based programmable payment product enables
//                 users to define payment rules triggered by specific events or
//                 milestones, such as quest rewards, DAO bounty or data billing
//                 based on API calls.
//               </Typography>
//             </div>
//           </div>
//         </Box>
//         <Box
//           sx={{ marginTop: 5, paddingTop: 7, paddingBottom: 7 }}
//           id="Product"
//         >
//           <Typography variant="h3">Product</Typography>
//           <div className="flex flex-row mt-10 gap-x-5 pt-5" id="Payroll">
//             <div className="basis-1/2 md:basis-full">
//               <Dashboard width="100%" height="100%" />
//             </div>
//             <div className="basis-1/2 md:basis-full">
//               <Typography
//                 variant="h4"
//                 component="div"
//                 sx={{ textAlign: "left", marginBottom: 12 }}
//               >
//                 Recurring Payroll
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="div"
//                 sx={{ textAlign: "left", marginTop: 5, lineHeight: "30px" }}
//               >
//                 MoveFlow's recurring payroll product enables employers to
//                 schedule regular salary payments to employees automatically,
//                 ensuring timely and accurate disbursements. This reduces the
//                 administrative burden minimizes the risk of human error and
//                 enhancing overall payroll management.
//               </Typography>
//             </div>
//           </div>
//         </Box>
//         <Box sx={{ paddingTop: 7 }} id="Vesting">
//           <div className="flex flex-row gap-x-5">
//             <div className="basis-1/2 sm:basis-full">
//               <Typography
//                 variant="h4"
//                 component="div"
//                 sx={{ textAlign: "left", marginBottom: 12 }}
//               >
//                 Token Vesting
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="div"
//                 sx={{ textAlign: "left", marginTop: 5, lineHeight: "30px" }}
//               >
//                 MoveFlow's token management product enables pre-scheduled and
//                 secure token vesting. Projects can implement cliff or linear
//                 vesting schedules, aligning stakeholder interests, safeguarding
//                 investor rights, and promoting long-term commitment.
//               </Typography>
//             </div>
//             <div className="basis-1/2 sm:basis-full">
//               <AddressBook width="100%" height="100%" />
//             </div>
//           </div>
//         </Box>
//         <Box sx={{ paddingTop: 7 }} id="Subscription">
//           <div className="flex flex-row mt-7 gap-x-5">
//             <div className="basis-1/2">
//               <Subscriptions width="100%" height="100%" />
//             </div>
//             <div className="basis-1/2">
//               <Typography
//                 variant="h4"
//                 component="div"
//                 sx={{ textAlign: "left", marginBottom: 12 }}
//               >
//                 Subscription
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="div"
//                 sx={{ textAlign: "left", marginTop: 5, lineHeight: "30px" }}
//               >
//                 MoveFlow's programmable transaction rules make it an ideal
//                 solution for managing subscription-based services. Service
//                 providers can automate subscription billing cycles, ensuring
//                 that users are charged at the specified intervals without manual
//                 intervention.
//               </Typography>
//             </div>
//           </div>
//         </Box>
//         <Box sx={{ paddingTop: 7 }} id="QuestReward">
//           <div className="flex flex-row mt-5 gap-x-5">
//             <div className="basis-1/2">
//               <Typography
//                 variant="h4"
//                 component="div"
//                 sx={{ textAlign: "left", marginBottom: 12 }}
//               >
//                 Quest Reward
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="div"
//                 sx={{ textAlign: "left", marginTop: 5, lineHeight: "30px" }}
//               >
//                 MoveFlow's Quest Reward feature addresses the realm of
//                 task-based marketing platforms. With this innovative capability,
//                 businesses can seamlessly implement and automate quest-based
//                 campaigns, incentivizing users to complete specific tasks or
//                 actions to earn rewards.
//               </Typography>
//             </div>
//             <div className="basis-1/2">
//               <img
//                 src={QuestReward}
//                 alt="noncegeek"
//                 className="block my-auto h-full p-3"
//               />
//               {/* <QuestReward width="100%" height="100%" /> */}
//             </div>
//           </div>
//         </Box>
//         <Box sx={{ paddingTop: 7 }}>
//           <div className="flex flex-row mt-10 gap-x-5">
//             <div className="basis-1/2">
//               <SDK width="100%" height="100%" />
//             </div>
//             <div className="basis-1/2">
//               <Typography
//                 variant="h4"
//                 component="div"
//                 sx={{ textAlign: "left", marginBottom: 12 }}
//               >
//                 Build on MoveFlow
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="div"
//                 sx={{ textAlign: "left", marginTop: 5, lineHeight: "30px" }}
//               >
//                 The MoveFlow SDK provides a robust set of tools, documentation,
//                 and resources, empowering developers to seamlessly integrate
//                 programmable payment features into their projects, so that
//                 businesses can streamline complex payment processes, enhance
//                 user experiences, and create a more efficient payment system.
//               </Typography>
//             </div>
//           </div>
//         </Box>
//         <Box sx={{ marginTop: 5, paddingTop: 7 }} id="Partner">
//           <Box>
//             <Typography variant="h3" component="div" sx={{ marginBottom: 14 }}>
//               Investors and Partners
//             </Typography>
//           </Box>
//         </Box>

//         <Box>
//           <div className="grid grid-cols-4 gap-10">
//             <div className="p-4">
//               <Aptos width="95%" height="95%" />
//             </div>
//             <div className="p-4">
//               <OV width="100%" height="100%" />
//             </div>
//             <div className="p-4">
//               <MSafe width="100%" height="100%" />
//             </div>
//             <div className="p-4">
//               <Zonff width="100%" height="100%" />
//             </div>

//             <div className="p-4 ">
//               <div className="my-auto h-full">
//                 <img
//                   src={MoveBit}
//                   alt="noncegeek"
//                   className="block my-auto h-full p-3"
//                   // height="100%"
//                 />
//               </div>
//             </div>
//             <div className="p-4">
//               <img src={MoveFuns} alt="MoveFuns" />
//             </div>
//             <div className="p-4">
//               <Antalpha width="120%" height="100%" />
//             </div>
//             <div className="p-4">
//               <img src={NonceGeek} alt="noncegeek" />
//             </div>
//           </div>
//         </Box>

//         <Box
//           sx={{
//             position: "relative",
//             bottom: "-100px",
//             marginTop: 5,
//             marginLeft: 0,
//             marginRight: 0,
//             paddingTop: 10,
//             paddingBottom: 10,
//             borderRadius: "8px",
//             zIndex: 2,
//             background: "linear-gradient(97.99deg, #262A31 0%, #0E111B 100%)",
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               gap: 5,
//               width: "100%",
//               py: 10,
//             }}
//           >
//             <Typography variant="h4" component="div" sx={{ fontWeight: 600 }}>
//               Get Started Now
//             </Typography>
//             <Typography
//               variant="body1"
//               component="div"
//               sx={{ textAlign: "center" }}
//             >
//               Automated payment streams to streamline your pre-defined
//               transaction rules.
//             </Typography>
//             <Button
//               variant="contained"
//               onClick={launchApp}
//               sx={{
//                 margin: "auto auto",
//                 width: 320,
//                 height: 78,
//                 fontSize: 20,
//                 fontWeight: 600,
//                 borderRadius: "8px",
//               }}
//             >
//               Launch App
//             </Button>
//           </Box>
//         </Box>
//       </Box>
//       <Footer />
//     </div>
//   );
// }

// export default App;

import { Box, Button, Typography } from "@mui/material";
import Footer from "components/Footer";
import Header from "components/Header";
// import MyCard, { MyCardProps } from "components/MyCard";
// import React from "react";
// import Albin from "resources/avatar/albin.png";
// import Bob from "resources/avatar/bob.png";
// import Burt from "resources/avatar/burt.png";
// import Murph from "resources/avatar/murph.png";
// import Terry from "resources/avatar/terry.png";
// import Troy from "resources/avatar/troy.png";
// import Vicky from "resources/avatar/vicky.png";
// import Walter from "resources/avatar/walter.png";
import "./App.css";

import { ReactComponent as HeadFigure } from "resources/head-figure.svg";
import Antalpha from "resources/partner/antalpha_labs.svg";
import Aptos from "resources/partner/aptos_logo.svg";
import MoveBit from "resources/partner/movebits.png";
import MoveFuns from "resources/partner/movefuns.png";
import MSafe from "resources/partner/msafe.svg";
import NonceGeek from "resources/partner/noncegeek.png";
import OV from "resources/partner/ov.svg";
import Zonff from "resources/partner/zonff_logo.svg";
import EventBase from "resources/products/EventBase 1.svg";
import QuestReward from "resources/products/Quest_Reward.svg";
// import { ReactComponent as QuestReward } from "resources/products/Quest.svg";
import SDK from "resources/products/SDK.svg";
import TimeBase from "resources/products/TimeBase 1.svg";
import AddressBook from "resources/products/address_book.svg";
import Dashboard from "resources/products/dashboard.svg";

function App() {
  const launchApp = () => {
    window.open("https://app.moveflow.xyz/");
  };

  const developerDoc = () => {
    window.open("https://moveflow.gitbook.io/moveflow/");
  };

  return (
    <>
      <div className="bg-[#212129]  w-full banner overflow-hidden mb-[-20px]">
        <p className="text-white px-4 py-2 mt-1 flex justify-center items-center text-sm marquee">
          Welcome to MoveFlow 2.0 on Aptos! We're migrating from v1 to v2 for
          better user experience. Click on{" "}
          <a
            href="https://app-v1.moveflow.xyz/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#f231f0] hover:underline"
          >
            https://app-v1.moveflow.xyz/ {""}
          </a>{" "}
          to access your original MoveFlow v1 streams.
        </p>
      </div>
      <div className="App container">
        <Header />

        <div className=" block md:flex mt-10 justify-between md:gap-0 lg:gap-32">
          <div className=" mt-10">
            <Typography
              variant="h2"
              component="div"
              sx={{
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: 14,
                fontSize: "47px",
                width: "100%",
                "@media (max-width: 767px)": {
                  fontSize: "38px",
                  width: "100%",
                  fontWeight: "bold",
                },
              }}
            >
              Multi-Chain Programmable Payment Protocol
            </Typography>

            <Typography
              variant="body1"
              component="div"
              sx={{
                textAlign: "left",
                marginTop: 5,
                lineHeight: "30px",
                maxWidth: "700px",
                fontSize: "20px",

                "@media (max-width: 767px)": {
                  marginTop: "-20px",
                },
              }}
            >
              MoveFlow is a programmable payment protocol that enables users to
              set up pre-defined transaction rules based on time and events. We
              help you streamline complex contract-based payment process through
              automated smart contracts.
            </Typography>

            <Box sx={{ display: "flex", alignItems: "start", gap: 5 }}>
              <Button
                variant="contained"
                onClick={launchApp}
                sx={{
                  marginTop: 10,
                  position: "relative",
                  left: 0,
                  width: 290,
                  height: 70,
                  fontSize: 20,
                  fontWeight: 600,
                  borderRadius: "8px",
                  "@media (max-width: 481px)": {
                    width: "300px",
                    height: "50px",
                    fontSize: "10px",
                    letterSpacing: "2px",
                  },
                }}
              >
                Launch App
              </Button>
              <Button
                variant="contained"
                onClick={developerDoc}
                sx={{
                  marginTop: 10,
                  position: "relative",
                  left: 0,
                  width: 290,
                  height: 70,
                  fontSize: 20,
                  fontWeight: 600,
                  color: "#FB36F4",
                  background: "transparent",
                  border: "2px solid #FB36F4",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "transparent",
                    boxShadow: "none",
                  },
                  "@media (max-width: 481px)": {
                    width: "300px",
                    height: "50px",
                    fontSize: "10px",
                    letterSpacing: "2px",
                  },
                }}
              >
                Developer Doc
              </Button>
            </Box>
          </div>
          <div className=" hidden lg:flex flex-1 relative bottom-20">
            <HeadFigure width="400px" />
          </div>
        </div>
        <div className="mt-14 md:mt-[-100px]">
          <div className=" ">
            <p className=" text-4xl md:text-5xl lg:text-5xl font-bold text-center mb-12">
              Programmable Payment Solution
            </p>
            <div className="block lg:flex justify-center items-center mx-auto flex-row m-5 gap-12 pt-2">
              <div className="mx-auto">
                <img
                  className="w-[100%] mx-auto lg:w-[100%]"
                  src={TimeBase}
                  alt=""
                />
                <p className="text-2xl  text-left font-bold my-6 ">
                  Time-based Programmable Payment Product{" "}
                </p>
                <p className="w-100 text-[#999999] md:w-100 lg:w-[600px] text-left text-base md:text-xl tracking-wider">
                  {" "}
                  MoveFlow's time-based programmable payment product allows
                  users to set up payment rules based on time parameters,
                  enabling automated payments like recurring payroll, token
                  management and subscription.
                </p>
              </div>
              <div className="mx-auto mt-[60px] md:mt-0">
                <img
                  className="w-[100%] mx-auto lg:w-[100%]"
                  src={EventBase}
                  alt=""
                />
                <p className="text-2xl  text-left font-bold my-6 ">
                  Event-based Programmable Payment Product{" "}
                </p>
                <p className="w-100 text-[#999999] md:w-100 lg:w-[600px] text-left text-base md:text-xl tracking-wider">
                  {" "}
                  MoveFlow's time-based programmable payment product allows
                  users to set up payment rules based on time parameters,
                  enabling automated payments like recurring payroll, token
                  management and subscription.
                </p>
              </div>
            </div>
          </div>
          <div className=" mt-10 lg:mt-20" id="Product">
            <p className=" text-4xl md:text-5xl lg:text-5xl font-bold text-center mb-[-20px] md:mb-12">
              Product
            </p>
            <div
              className="block md:flex  justify-between gap-16 pt-5 mt-14 md:mt-32"
              id="Payroll"
            >
              <div className="">
                <img
                  className="w-full mx-auto lg:w-[600px]"
                  src={AddressBook}
                  alt=""
                />
              </div>

              <div className="">
                <p className="text-2xl text-left font-bold my-6 ">
                  Token Vesting
                </p>
                <p className="w-full text-[#999999] md:w-[550px] text-left text-base md:text-xl gap-12 tracking-wider">
                  MoveFlow's token management product enables pre-scheduled and
                  secure token vesting. Projects can implement cliff or linear
                  vesting schedules, aligning stakeholder interests,
                  safeguarding investor rights, and promoting long-term
                  commitment.
                </p>
              </div>
            </div>

            <div
              className="block md:flex mt-14 md:mt-32 justify-between gap-16 pt-5"
              id="Vesting"
            >
              {/* On mobile, display image first */}
              <div className="order-1 md:order-2">
                <img
                  className="w-[100%] mx-auto lg:w-[600px]"
                  src={Dashboard}
                  alt=""
                />
              </div>
              <div className="order-2 md:order-1">
                <p className="text-2xl  text-left font-bold my-6 ">
                  Recurring Payroll
                </p>
                <p className="w-100 text-[#999999] md:w-[550px]  text-left text-base md:text-xl gap-12 tracking-wider">
                  MoveFlow's recurring payroll product enables employers to
                  schedule regular salary payments to employees automatically,
                  ensuring timely and accurate disbursements. This reduces the
                  administrative burden minimizes the risk of human error and
                  enhancing overall payroll management.
                </p>
              </div>
            </div>

            <div
              className="block md:flex mt-14 md:mt-32  justify-between gap-16 pt-5"
              id="Subscription"
            >
              <div className="">
                <img
                  className="w-[100%] mx-auto lg:w-[600px]"
                  src="https://move-flow.github.io/assets/subscriptions.svg"
                  alt=""
                />
              </div>
              <div className="">
                <p className="text-2xl  text-left font-bold my-6 ">
                  Subscription
                </p>
                <p className="w-100 text-[#999999] md:w-[550px]  text-left text-base md:text-xl gap-12 tracking-wider">
                  MoveFlow's programmable transaction rules make it an ideal
                  solution for managing subscription-based services. Service
                  providers can automate subscription billing cycles, ensuring
                  that users are charged at the specified intervals without
                  manual intervention.
                </p>
              </div>
            </div>
            <div
              className="block md:flex mt-14 md:mt-32 justify-between gap-16 pt-5"
              id="QuestReward"
            >
              <div className="order-1 md:order-2">
                <img
                  className="w-[100%] mx-auto lg:w-[600px]"
                  src={QuestReward}
                  alt=""
                />
              </div>

              <div className="order-2 md:order-1">
                <p className="text-2xl  text-left font-bold my-6 ">
                  Quest Reward
                </p>
                <p className="w-100 text-[#999999] md:w-[550px]  text-left text-base md:text-xl gap-12 tracking-wider">
                  MoveFlow's Quest Reward feature addresses the realm of
                  task-based marketing platforms. With this innovative
                  capability, businesses can seamlessly implement and automate
                  quest-based campaigns, incentivizing users to complete
                  specific tasks or actions to earn rewards.
                </p>
              </div>
            </div>

            <div
              className="block md:flex mt-14 md:mt-32  justify-between gap-16 pt-5"
              id="Payroll"
            >
              <div className="">
                <img
                  className="w-[100%] mx-auto lg:w-[600px]"
                  src={SDK}
                  alt=""
                />
              </div>
              <div className="">
                <p className="text-2xl  text-left font-bold my-6 ">
                  Build on MoveFlow
                </p>
                <p className="w-100 text-[#999999] md:w-[550px]  text-left text-base md:text-xl gap-12 tracking-wider">
                  The MoveFlow SDK provides a robust set of tools,
                  documentation, and resources, empowering developers to
                  seamlessly integrate programmable payment features into their
                  projects, so that businesses can streamline complex payment
                  processes, enhance user experiences, and create a more
                  efficient payment system.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-14 lg:mt-32" id="Partner">
          <p className=" text-4xl md:text-5xl lg:text-5xl font-bold text-center mb-[-20px] md:mb-12">
            Investors and Partners
          </p>
        </div>

        <Box>
          <div className="mt-12 grid grid-cols-2 items-center mx-auto justify-center md:grid-cols-4 gap-10">
            <div className="flex justify-center items-center">
              <img className="w-[90px] lg:w-[150px]" src={Aptos} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img className="w-[100px] lg:w-[150px]" src={OV} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img className="w-[100px] lg:w-[150px]" src={MSafe} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img className="w-[100px] lg:w-[150px]" src={Zonff} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img
                className="w-[100px] lg:w-[150px]"
                src={MoveBit}
                alt="noncegeek"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                className="w-[100px] lg:w-[150px]"
                src={MoveFuns}
                alt="MoveFuns"
              />
            </div>
            <div className="flex justify-center items-center">
              <img className="w-[100px] lg:w-[150px]" src={Antalpha} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img
                className="w-[100px] lg:w-[150px]"
                src={NonceGeek}
                alt="noncegeek"
              />
            </div>
          </div>
        </Box>

        <Box
          sx={{
            position: "relative",
            bottom: "-100px",
            marginTop: 5,
            marginLeft: 0,
            marginRight: 0,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: "8px",
            zIndex: 2,
            background: "linear-gradient(97.99deg, #262A31 0%, #0E111B 100%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              width: "100%",
              py: 10,
            }}
          >
            <p className="text-2xl md:text-5xl font-bold">Get Started Now</p>
            <p className="text-center mx-auto">
              Automated payment streams to streamline your pre-defined
              transaction rules.
            </p>
            <Button
              variant="contained"
              onClick={launchApp}
              sx={{
                margin: "auto auto",
                width: ["200px", "250px"], // Adjust width for different screen sizes
                height: ["50px", 68],
                fontSize: ["12px", 20], // Adjust font size for different screen sizes
                fontWeight: 600,
                letterSpacing: "1.5px",
                borderRadius: "8px",
              }}
            >
              Launch App
            </Button>
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
}

export default App;
