import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { ReactComponent as MoveFlowLogo } from "resources/MFlogo.svg";
import { ReactComponent as GitbookLogo } from "resources/gitbook.svg";

const Footer = () => {
  const handleIconClick = (url: string) => {
    window.open(url);
  };

  const handleScroll = (link: string) => {
    let anchorElement = document.getElementById(link);
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: "start", inline: "nearest" });
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        bottom: 0,
        paddingTop: 25,
        paddingBottom: 15,
        background: "linear-gradient(95.75deg, #292441 0%, #16122B 100%);",
        height: "100%",
      }}
    >
      <div className=" container flex-col lg:flex lg:flex-row justify-between">
        <div>
          <MoveFlowLogo height="30px" width="150px" style={{ left: 0 }} />
          <p className="w-[100%] md:w-[700px] my-7">
            MoveFlow is a programmable payment protocol that enables users to
            set up pre-defined transaction rules based on time and events. We
            help you streamline complex contract-based payment process through
            automated smart contracts.
          </p>
          <div className="flex flex-row items-center">
            <IconButton
              aria-label="github"
              onClick={() => {
                handleIconClick("https://github.com/Move-Flow");
              }}
              sx={{ paddingLeft: 0 }}
            >
              <GitHubIcon sx={{ color: "grey" }} fontSize="large" />
            </IconButton>
            <IconButton
              onClick={() => {
                handleIconClick("https://twitter.com/moveflowlabs");
              }}
            >
              <TwitterIcon sx={{ color: "grey" }} fontSize="large" />
            </IconButton>
            <IconButton
              onClick={() => {
                handleIconClick("https://moveflow.gitbook.io/moveflow/");
              }}
            >
              <GitbookLogo />
            </IconButton>
          </div>
        </div>
        <div className=" mt-8 lg:mt-auto flex gap-9">
          <div className="flex-col">
            <Typography
              variant="body2"
              component="div"
              sx={{ fontWeight: 600, color: "#FFFFFF" }}
            >
              Solutions
            </Typography>

            <div>
              <Link
                variant="body2"
                component="button"
                underline="none"
                sx={{ fontWeight: 400 }}
                onClick={() => {
                  handleScroll("Vesting");
                }}
              >
                Vesting
              </Link>
            </div>
            <div className="my-2">
              <Link
                variant="body2"
                component="button"
                underline="none"
                sx={{ fontWeight: 400 }}
                onClick={() => {
                  handleScroll("Payroll");
                }}
              >
                Payroll
              </Link>
            </div>
            <div className="my-2">
              <Link
                variant="body2"
                component="button"
                underline="none"
                sx={{ fontWeight: 400 }}
                onClick={() => {
                  handleScroll("Subscription");
                }}
              >
                Subscription
              </Link>
            </div>
            <div>
              <Link
                variant="body2"
                component="button"
                underline="none"
                sx={{ fontWeight: 400 }}
                onClick={() => {
                  handleScroll("QuestReward");
                }}
              >
                Quest reward
                <br />
                (Coming Soon)
              </Link>
            </div>
          </div>

          <div className="flex flex-col">
            <Typography
              variant="body2"
              component="div"
              sx={{ fontWeight: 600, color: "#FFFFFF" }}
            >
              Developers
            </Typography>
            <div className="mb-1">
              <Link
                variant="body2"
                component="button"
                underline="none"
                sx={{ fontWeight: 400 }}
                onClick={() => {
                  handleIconClick("https://moveflow.gitbook.io/moveflow/");
                }}
              >
                Docs
              </Link>
            </div>
            <div className="">
              <Link
                variant="body2"
                component="button"
                underline="none"
                sx={{ fontWeight: 400 }}
                onClick={() => {
                  handleIconClick("https://github.com/Move-Flow");
                }}
              >
                Github
              </Link>
            </div>
          </div>
          <div className="flex flex-col">
            <Typography
              variant="body2"
              component="div"
              sx={{ fontWeight: 600, color: "#FFFFFF" }}
            >
              About
            </Typography>
            <div className="mt-1">
              <Link
                variant="body2"
                underline="none"
                sx={{ fontWeight: 400 }}
                href="mailto:moveflow.xyz@gmail.com"
              >
                Email us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          component="div"
          sx={{
            fontSize: 12,
            textAlign: "center",
          }}
        >
          © 2024 MoveFlow. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
